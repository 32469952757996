<template>
  <edit-role-form
      :abilities="abilities"
      :is-loading="isLoading"
      :repository="repository"
      :role-name="name"
      is-update-form
      @refresh-data="$router.replace({name: 'associated-roles'})"
  />
</template>

<script>
import EditRoleForm from '@/components/roles/form.vue'
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  components: {
    EditRoleForm,
  },
  data() {
    return {
      name: "",
      abilities: {},
      isLoading: false,
      repository: RepositoryFactory.create('associatedRoleRepo'),
    }
  },
  async created() {
    this.isLoading = true
    await this.showRecord()
    this.isLoading = false
  },
  methods: {
    async showRecord() {
      await this.repository.get(this.$route.params.id)
          .then(res => {
            this.name = res.data.data.name
            this.abilities = res.data.data.permissionsModularity.associated ?? {}
          });
    },
  }
}
</script>
